import RawHtml from 'utils/miscUtils/rawHtml';
import useDateTime from 'hooks/useDateTime';
import { Author } from 'interfaces/content/Authors';
import Link from 'components/Globals/Base/Link';
import BylineList from 'components/Byline';
import ArticleHeroEyebrow from 'components/ArticleHeroEyebrow';
import { EventData } from 'services/Gtm/entities';
import { Card, PubDate, Wrapper, TitleLink } from './CompanyLatestStyles';
import { CardInfoWrapper } from './CommonStyle';

export interface CompanyLatestProps {
  eyebrowSectionLabel: string;
  eyebrowSectionLink: string;
  eyebrowTagLabel?: string;
  eyebrowTagLink?: string;
  title: string;
  titleLink: string;
  postDateGmt: string;
  bylineList: Author[];
  classname?: string;
  trackingData?: EventData;
}

const CompanyLatest = ({
  eyebrowSectionLabel,
  eyebrowSectionLink,
  eyebrowTagLabel,
  eyebrowTagLink,
  title,
  titleLink,
  postDateGmt,
  bylineList,
  classname = '',
  trackingData,
}: CompanyLatestProps) => {
  const localDate = useDateTime(postDateGmt);
  const recommendsArticle = titleLink.match(/recommends/g);

  return (
    <Wrapper className={classname}>
      <Card>
        <ArticleHeroEyebrow
          primarySection={{ link: eyebrowSectionLink, name: eyebrowSectionLabel }}
          primaryPostTag={{ link: eyebrowTagLink || '', name: eyebrowTagLabel || '' }}
          trackingData={trackingData}
        />
        <Link
          href={titleLink}
          trackingData={
            trackingData && {
              ...trackingData,
              eventLabel: `Title click: ${title}`,
            }
          }
        >
          <TitleLink>{RawHtml({ html: title })}</TitleLink>
        </Link>
        <CardInfoWrapper>
          <BylineList
            bylineList={bylineList}
            trackingData={
              trackingData && {
                ...trackingData,
                eventLabel: 'author click',
              }
            }
          />
          {!recommendsArticle && <PubDate>{localDate}</PubDate>}
        </CardInfoWrapper>
      </Card>
    </Wrapper>
  );
};

export default CompanyLatest;
