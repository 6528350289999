import styled from 'styled-components';
import { breakpoint } from 'styles/globals';
import { Theme, getBodyStyle, getColor } from 'styles/theme';

const Wrapper = styled.div<{ theme: Theme }>`
  ${getBodyStyle('graphikCond', { default: 'small', mdMin: 'default' }, 'semiBold')};
  color: ${getColor('textAccentSecondary')};
  letter-spacing: 2px;

  &.eyebrow {
    min-height: 21px;
    margin: 0 0 8px;
    ${getBodyStyle('graphikCond', { default: 'default' })};
    letter-spacing: 1.2px;
  }

  .link {
    text-transform: uppercase;
    padding-left: 3px;
    font-weight: 600;
  }

  @media (${breakpoint.mdMin}) {
    font-size: 16px;
  }
`;

export default Wrapper;
