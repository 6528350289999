import styled from 'styled-components';
import { breakpoint } from 'styles/globals';
import { Theme, getBodyStyle, getColor } from 'styles/theme';

export const Wrapper = styled.li`
  list-style: none;
  lost-column: 1/1;

  @media (${breakpoint.mdMin}) {
    lost-column: 1/2;
  }

  @media (${breakpoint.lgMin}) {
    lost-column: 1/4;
    padding: 0;
  }
`;

export const Card = styled.div`
  padding-top: 16px;

  @media (${breakpoint.lgMin}) {
    padding-top: 12px;
  }
`;

export const TitleLink = styled.span<{ theme: Theme }>`
  padding-top: 8px;
  display: block;
  ${getBodyStyle('graphikCond', { default: 'large' }, 'semiBold')};

  &:hover {
    color: ${getColor('textLink')};
  }
`;

export const PubDate = styled.span<{ theme: Theme }>`
  ${getBodyStyle('graphikCond', { default: 'small' })};
  color: ${getColor('textSecondary')};
  letter-spacing: 1.17px;
  margin-top: 4px;
  min-height: 21px;
`;
