import { timeAgo } from 'utils/dateUtils';
import useIsBrowser from './useIsBrowser';

export default function useTimeAgo(postDateGmt: string): string {
  const isBrowser = useIsBrowser();

  if (isBrowser) {
    // eslint-disable-next-line no-param-reassign
    postDateGmt = postDateGmt.replace(/ /g, 'T');

    return timeAgo(`${postDateGmt}Z`);
  }

  return '0 minutes ago';
}
