import styled from 'styled-components';
import { font } from 'styles/globals';
import { getColor, Theme } from 'styles/theme';

export const CardInfoWrapper = styled.div`
  &, &.card-info-wrapper {
    letter-spacing: 1.13px;
    position: relative;
    z-index: 2;
  }
`;

interface SectionLinkProps {
  $type?: string;
}

export const Hyphen = styled.span<{ theme: Theme }>`
  &, &.hyphen {
    color: ${getColor('textAccentSecondary')};
    font-family: ${font.graphikCond};
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 24px;
    text-transform: uppercase;
    text-decoration: none;
  }
`;

export const SectionLink = styled.span<SectionLinkProps>`
  &, &.link-wrapper {
    font-family: ${font.graphikCond};
    font-size: ${(props) => (props.$type === 'article-redesign-latest' ? '14px' : '16px')};
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 24px;
    text-transform: uppercase;
    text-decoration: none;
    position: relative;

    > a {
      position: relative;
      z-index: 2;
    }
  }
`;
