import { useEffect, useState } from 'react';
import { DateTimeFormat, formatDateTime } from 'utils/dateUtils';
import useIsBrowser from './useIsBrowser';

/**
 * NOTE: dateGmt should always be in GMT/UTC time, do NOT pass in local timestamp.
 * Appending the 'Z' to the end of the string indicates that it is in GMT/UTC time.
 *
 * @param dateGmt 'YYYY-MM-DD 00:00:00' | 'YYYY-MM-DDT00:00:00'
 * @param fmt 'datetime' | 'date'
 * @returns '00 Month, YYYY, 00:00 AM/PM EST' | '00 Month, YYYY'
 */

export default function useDateTime(dateGmt: string, fmt: DateTimeFormat = 'datetime'): string | null {
  const isBrowser = useIsBrowser();

  const [dateTime, setDateTime] = useState<string | null>(null);

  useEffect(() => {
    setDateTime(formatDateTime(`${dateGmt}Z`, fmt));
  }, [dateGmt, fmt, isBrowser]);

  return dateTime;
}
