import React, { ForwardedRef } from 'react';
import RawHtml from 'utils/miscUtils/rawHtml';
import useTimeAgo from 'hooks/useTimeAgo';
import Link from 'components/Globals/Base/Link';
import * as S from './HomeLatestStyles';

export interface HomeLatestProps {
  title: string;
  titleLink: string;
  section: string;
  date: string;
  className?: string;
}

const HomeLatest = (
  { title, titleLink, section, date, className }: HomeLatestProps,
  ref?: ForwardedRef<HTMLLIElement> | null,
) => {
  const timeAgo = useTimeAgo(date);

  const trackingData = {
    contentPlacementCD: section,
    eventAction: 'latest',
    eventCategory: 'article',
    eventLabel: `title click : ${title}`,
  };

  return (
    <S.Wrapper
      ref={ref}
      className={className}
    >
      <S.Card>
        <Link
          href={titleLink}
          className='title-link'
          trackingData={trackingData}
          type='primary'
        >
          {RawHtml({ html: title })}
        </Link>
        <br />
        {date ? <S.TimeAgo>{timeAgo}</S.TimeAgo> : <br />}
      </S.Card>
    </S.Wrapper>
  );
};

export default React.forwardRef(HomeLatest);
