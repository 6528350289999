export const ARTICLE_PAGE_TEST_IDS = {
  AUTHORS: 'article-authors',
  AUTHORS_BIO_CARDS: 'authors-bio-cards',
  AUTHORS_BIO_SECTION_TITLE: 'authors-bio-section-title',
  AUTHOR_AND_DATE: 'article-author-and-date',
  BRANDED_ARTICLE: 'branded-article',
  CAPTION: 'caption',
  CONTAINER_EYEBROW: 'article-container-eyebrow',
  CONTENT: 'article-content',
  CREDIT: 'credit',
  EDITORIAL_DISCLOSURE: 'editorial-disclosure',
  EYEBROW: 'article-eyebrow',
  HEADER_DATE: 'header-date',
  IMAGE: 'article-image',
  JUMPTO: 'jump-to',
  POST_LIST: 'post-list',
  RIGHT_RAIL: 'article-right-rail',
  SCHOOL_PAGE_BANNER: 'school-page-banner',
  SCHOOL_PAGE_RIGHT_RAIL: 'school-page-right-rail',
  SECTION_EYEBROW: 'article-section-eyebrow',
  SERIES_LIST: 'article-series-list',
  SERIES_TOP: 'article-series-top',
  TAG_EYEBROW: 'article-tag-eyebrow',
  TITLE: 'article-title',
};

export type TestIds = {
  [key in keyof typeof ARTICLE_PAGE_TEST_IDS]?: string;
};

const getArticlePageTestId = (value: keyof TestIds) => ARTICLE_PAGE_TEST_IDS[value];

export default getArticlePageTestId;
