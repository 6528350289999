import styled from 'styled-components';
import { Theme, getBodyStyle, getColor } from 'styles/theme';
import { imageAndTitleLinkHover } from 'styles/imageAndTitleLinkHover';
import { font } from 'styles/globals';

export const Wrapper = styled.li<{ theme: Theme }>`
  &, &.content {
    position: relative;
    ${imageAndTitleLinkHover}
  }
`;

export const Card = styled.div`
  &, &.card {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .card-image{
      aspect-ratio: 735 / 490;
      width: 100%;
      height: auto;
   }
`;

export const TitleLink = styled.h2<{ theme: Theme; $hasEyebrow: boolean }>`
  &, &.title-link-wrapper {
    font-family: ${font.saolText};
    font-size: 24px;
    font-weight: 600;
    text-align: left;
    text-decoration: none;
    line-height: 28px;
    display: block;

    > a::before {
      content: '';
      position: absolute;
      inset: 0;
      z-index: 1;
    }
  }
`;

export const PubDate = styled.time<{ theme: Theme }>`
  &, &.date {
    ${getBodyStyle('graphikCond', { default: 'small' }, 'light')};
    color: ${getColor('textSecondary')};
    letter-spacing: 1.17px;
    min-height: 21px;
    position: relative;
    z-index: 2;
  }
`;
